import React from "react";

import Layout from "../../components/Layout";
import ReviewersSection from "../../content/ReviewerSection";

const Reviewers = (props) => {
  return (
    <Layout>
      <ReviewersSection isPage />
    </Layout>
  );
};

export default Reviewers;
